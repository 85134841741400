/* eslint-disable default-case */
import $ from 'jquery';
import 'bootstrap-fileinput/js/fileinput';
import 'bootstrap-fileinput/js/locales/de';
import AjaxForm from './../AjaxForm';
import Modal from './../Modal';
import FileUpload from './../FileUpload';

const IdeaScoutForm = (() => {
  let options = {
    selectorModal: '',
    selectorTrigger: '',
    selectorForm: 'form',
  };

  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      const $reportForm = $$(options.selectorModal);

      $(options.selectorTrigger).click(function () {
        Modal.show($(this));
        return false;
      });

      const $form = $reportForm.find(options.selectorForm);
      const $upload = $('#idea_scout_form_images');

      FileUpload.image($upload, $form.attr('action'), 3);

      AjaxForm.setup($form, true, () => {
        const $header = $reportForm.find('.header');
        $header.find('h3').text('Vielen Dank für Ihre Idee!');
        $header.find('.sub').slideUp();
      });
    },
  };
})();

export default IdeaScoutForm;

import axios from 'axios';
import Handlebars from 'handlebars';

const HandlebarsLoader = (() => {
  let options = {
    baseUrl: '',
  };
  const cache = {};
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
    },
    get(file, fn) {
      const url = options.baseUrl + file;
      if (typeof cache[url] !== 'undefined') {
        fn(cache[url]);
      } else {
        axios.get(url)
          .then((response) => {
            cache[url] = Handlebars.compile(response.data);
            fn(cache[url]);
          })
          .catch((error) => {
            console.log(error);
            fn(null);
          });
      }
    },
  };
})();

export default HandlebarsLoader;

import 'jquery';
import ResizeSensor from 'resize-sensor';
import Variables from './Variables';
import Utility from './Utility';

const FixedInContainer = (() => {
  let options = {
    selectorContainer: '',
    selectorElement: '',
    additionalOffset: 0,
  };
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      const $window = $$(window);
      const $container = $$(options.selectorContainer);
      const $element = $$(options.selectorElement);

      if ($container.length <= 0 || $element.length <= 0) {
        return;
      }

      let maxOffset = $container.outerHeight() - $element.outerHeight();
      const check = function (calculateOffset) {
        if (calculateOffset) {
          maxOffset = $container.outerHeight() - $element.outerHeight();
        }
        if ($window.width() <= Variables.breakpoints.sm) {
          $element.removeClass('sticky');
          $element.removeClass('bottom');
          return;
        }
        const offsetTop = $container.offset().top + options.additionalOffset;
        const scrollTop = $window.scrollTop();
        if (scrollTop >= offsetTop) {
          $element.addClass('sticky');
        } else {
          $element.removeClass('sticky');
        }
        if (scrollTop >= offsetTop + maxOffset) {
          $element.addClass('bottom');
        } else {
          $element.removeClass('bottom');
        }
      };
      Utility.onWindowLoad(() => {
        check(true);
      });
      Utility.onDocumentReady(() => {
        check(true);
        new ResizeSensor($container.eq(0), (() => {
          check(true);
        }));
      });
      $window.on('resize orientationchange', () => {
        check(true);
      });
      $window.on('scroll', () => {
        check();
      });
    },
  };
})();

export default FixedInContainer;

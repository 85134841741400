/* eslint-disable default-case */
import $ from 'jquery';
import 'bootstrap-fileinput/js/fileinput';
import 'bootstrap-fileinput/js/locales/de';
import AjaxForm from './../AjaxForm';
import GoogleComplete from './../GoogleComplete';
import Modal from './../Modal';
import GoogleMap from '../../../../_modules/google-map/GoogleMap';
import FileUpload from './../FileUpload';

const ReportForm = (() => {
  let options = {
    selectorModal: '',
    selectorTrigger: '',
    selectorAutoComplete: '',
    selectorGeoLocate: '',
    selectorMap: '.google-map',
    form: {
      selector: 'form',
      street: 'street',
      streetNumber: 'street_number',
      zipCode: 'zip_code',
      district: 'district',
      latitude: 'latitude',
      longitude: 'longitude',
      formattedAddress: 'location',
    },
  };
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
  };
  let circle;
  let marker;
  let geocoder = null;
  let mapIndex = 0;
  let mapInitialized = false;
  let autoCompleteInitialized = false;
  let geolocatePosition = null;

  const getMap = function () {
    return GoogleMap.getMapsByName('report-form')[mapIndex];
  };

  const setFields = function (args) {
    const $reportForm = $$(options.selectorModal);
    $reportForm.find(`input[name=${options.form.street}]`).val(args.street || '');
    $reportForm.find(`input[name=${options.form.streetNumber}]`).val(args.streetNumber || '');
    $reportForm.find(`input[name=${options.form.zipCode}]`).val(args.zipCode || '');
    $reportForm.find(`input[name=${options.form.district}]`).val(args.city || '');
    $reportForm.find(`input[name=${options.form.formattedAddress}]`).val(args.formattedAddress || '');

    const map = getMap();
    const center = map.getCenter();
    $reportForm.find(`input[name=${options.form.latitude}]`).val(center.lat() || '');
    $reportForm.find(`input[name=${options.form.longitude}]`).val(center.lng() || '');
  };

  const initializeMap = function ($googleMap, callbackFn) {
    if (mapInitialized === false) {
      mapInitialized = true;
      mapIndex = GoogleMap.getMapsByName('report-form').length;
      $googleMap.slideDown(150, () => {
        GoogleMap.onInitialized((map, key) => {
          if (key === 'report-form') {
            callbackFn(getMap());
          }
        });
        GoogleMap.initialize({
          selector: `${options.selectorModal} ${options.selectorMap}`,
        });
      });
    } else {
      callbackFn(getMap());
    }
  };

  const handleEvent = function (event) {
    if (geocoder === null) {
      geocoder = new google.maps.Geocoder();
    }
    geocoder.geocode({
      location: {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      },
    }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const place = results[0];
          const formattedAddress = place.formatted_address;
          let postalCode;
          let locality;
          let streetNumber;
          let route;
          for (let j = 0; j < place.address_components.length; j++) {
            const addressType = place.address_components[j].types[0];
            const val = place.address_components[j][componentForm[addressType]];
            switch (addressType) {
              case 'postal_code':
                postalCode = val;
                break;
              case 'locality':
                locality = val;
                break;
              case 'street_number':
                streetNumber = val;
                break;
              case 'route':
                route = val;
                break;
            }
          }
          const args = {
            city: locality,
            zipCode: postalCode,
            street: route,
            streetNumber,
            formattedAddress,
          };
          setFields(args);
        }
      }
    });
  };

  const setupMarker = function (map, center) {
    if (typeof circle !== 'undefined') {
      circle.setMap(null);
    }
    if (typeof marker !== 'undefined') {
      marker.setMap(null);
    }
    map.setCenter(center);
    circle = new google.maps.Circle({
      strokeColor: '#413692',
      strokeOpacity: 0.2,
      strokeWeight: 2,
      fillColor: '#413692',
      fillOpacity: 0.1,
      map,
      draggable: false,
      center,
      radius: 50,
    });
    marker = new google.maps.Marker({
      map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: circle.center,
    });
    let prevPosition = circle.center;
    marker.addListener('dragend', (event) => {
      if (!circle.getBounds().contains(marker.position)
        || google.maps.geometry.spherical.computeDistanceBetween(circle.getCenter(), marker.position) > circle.getRadius()) {
        marker.setPosition(prevPosition);
      } else {
        prevPosition = marker.position;
      }
      event.latLng = marker.position;
      handleEvent(event);
    });
    handleEvent({ latLng: marker.position });
  };

  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      const $reportForm = $$(options.selectorModal);
      const $geoLocate = $reportForm.find(options.selectorGeoLocate);
      const $googleMap = $reportForm.find(options.selectorMap);

      $geoLocate.click((e) => {
        e.preventDefault();
        initializeMap($googleMap, (map) => {
          if (navigator.geolocation) {
            if (geolocatePosition === null || typeof geolocatePosition === 'undefined') {
              navigator.geolocation.getCurrentPosition((position) => {
                geolocatePosition = position.coords;
                if (map !== null && typeof map !== 'undefined') {
                  setupMarker(map, new google.maps.LatLng(geolocatePosition.latitude, geolocatePosition.longitude));
                }
              });
            } else if (map !== null && typeof map !== 'undefined') {
              setupMarker(map, new google.maps.LatLng(geolocatePosition.latitude, geolocatePosition.longitude));
            }
          }
        });
      });

      $(options.selectorTrigger).click(function () {
        Modal.show($(this));
        if (!autoCompleteInitialized) {
          autoCompleteInitialized = true;
          GoogleComplete.initialize({
            selector: options.selectorAutoComplete,
          });
          $(options.selectorAutoComplete).change(() => {
            $reportForm.find(`input[name=${options.form.latitude}]`).val('');
            $reportForm.find(`input[name=${options.form.longitude}]`).val('');
          });
          GoogleComplete.onAddressChanged(($element, geometry) => {
            initializeMap($googleMap, (map) => {
              if (map !== null && typeof map !== 'undefined') {
                setupMarker(map, geometry.location);
              }
            });
          });
        }
        return false;
      });

      const $form = $reportForm.find(options.form.selector);
      const $upload = $('#report_form_images');

      FileUpload.image($upload, $form.attr('action'), 3);

      AjaxForm.setup($form, true, () => {
        $reportForm.find('.google-map').slideUp();
        const $header = $reportForm.find('.header');
        $header.find('h3').text('Vielen Dank für Ihre Meldung!');
        $header.find('.sub').slideUp();
      });
    },
  };
})();

export default ReportForm;

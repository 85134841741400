import Handlebars from 'handlebars';
import moment from 'moment';
import './../MomentLocale';

moment.relativeTimeThreshold('s', 1);
moment.relativeTimeThreshold('ss', 2);

moment.fn.fromNowOrNow = function (a) {
  if (Math.abs(moment().diff(this)) < 1000) {
    return 'Gerade jetzt';
  }
  return this.fromNow(a);
};

moment.fn.untilNowOrNow = function (a) {
  if (Math.abs(moment().diff(this)) < 1000) {
    return 'Abgelaufen';
  }
  return this.fromNow(a);
};

Handlebars.registerHelper('debug', function (optionalValue) {
  console.log('\nCurrent Context');
  console.log('====================');
  console.log(this);

  if (arguments.length > 1) {
    console.log('Value');
    console.log('====================');
    console.log(optionalValue);
  }
});

Handlebars.registerHelper('slice', (context, block) => {
  let ret = '';
  const offset = parseInt(block.hash.offset, 10) || 0;
  const limit = parseInt(block.hash.limit, 10) || 5;
  const j = ((limit + offset) < context.length) ? (limit + offset) : context.length;
  let i = (offset < context.length) ? offset : 0;
  for (i, j; i < j; i++) {
    ret += block(context[i]);
  }
  return ret;
});

Handlebars.registerHelper('toSentence', (context, block) => {
  let ret = '';
  for (let i = 0, j = context.length; i < j; i++) {
    ret += block(context[i]);
    if (i < j - 1) {
      ret += ', ';
    }
  }
  return ret;
});

Handlebars.registerHelper('dateFormat', (context, block) => {
  if (moment && context && moment(context).isValid()) {
    const f = block.hash.format || 'MMM Do, YYYY';
    return moment(context).format(f);
  }
  return context;
});

Handlebars.registerHelper('timeAgo', (context) => {
  if (moment && context && moment(context).isValid()) {
    return moment(context).fromNowOrNow();
  }
  return context;
});

Handlebars.registerHelper('urlEncode', (context) => {
  if (context) {
    return encodeURIComponent(context);
  }
  return context;
});

Handlebars.registerHelper('urlEncodeConcat', (v1, v2) => encodeURIComponent(v1 + v2));

Handlebars.registerHelper('concat', (v1, v2) => v1 + v2);

Handlebars.registerHelper('truncate', (v1, v2, v3) => {
  const cleaned = v1.replace(/(<([^>]+)>)/ig, '');
  let result;
  if (v3) {
    const regex = `^(.{${v2}}[^\\s]*).*`;
    result = cleaned.replace(new RegExp(regex), '$1');
  } else {
    result = cleaned.substring(0, v2);
  }

  return result + (cleaned.length > v2 ? '...' : '');
});

Handlebars.registerHelper('timeUntil', (context) => {
  if (moment && context && moment(context).isValid()) {
    return moment(context).untilNowOrNow();
  }
  return context;
});

Handlebars.registerHelper('ifEquals', function (v1, v2, options) {
  if (v1 === v2) {
    return options.fn(this);
  }
  return options.inverse(this);
});

Handlebars.registerHelper('entityHasImage', (context) => {
  if (context) {
    if (typeof context === 'undefined' || context === null || !Array.isArray(context)) {
      return false;
    }
    for (let i = 0; i < context.length; i++) {
      const asset = context[i];
      if (asset.isImage && !asset.isDownload) {
        return true;
      }
    }
  }
  return false;
});

Handlebars.registerHelper('entityGetFirstImage', (context) => {
  if (context) {
    if (typeof context === 'undefined' || context === null || !Array.isArray(context)) {
      return null;
    }
    for (let i = 0; i < context.length; i++) {
      const asset = context[i];
      if (asset.isImage && !asset.isDownload) {
        return asset;
      }
    }
  }
  return null;
});

Handlebars.registerHelper('entityGetDownloads', (context) => {
  const assets = [];
  if (context) {
    if (typeof context === 'undefined' || context === null || !Array.isArray(context)) {
      return assets;
    }
    for (let i = 0; i < context.length; i++) {
      const asset = context[i];
      if (asset.isDownload) {
        assets.push(asset);
      }
    }
  }
  return assets;
});

Handlebars.registerHelper('entityGetImages', (context) => {
  const assets = [];
  if (context) {
    if (typeof context === 'undefined' || context === null || !Array.isArray(context)) {
      return assets;
    }
    for (let i = 0; i < context.length; i++) {
      const asset = context[i];
      if (asset.isImage && !asset.isDownload) {
        assets.push(asset);
      }
    }
  }
  return assets;
});

Handlebars.registerHelper('setVariable', (varName, varValue, options) => {
  options.data.root[varName] = varValue;
});

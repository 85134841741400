import $ from 'jquery';
import select2 from 'select2';

const Select = (() => {
  let options = {
    selector: '.custom-select select:not(.select2-hidden-accessible)',
  };
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      const $elements = $$(options.selector);
      if ($elements.length > 0) {
        select2();
        // eslint-disable-next-line global-require
        require('../../../../node_modules/select2/dist/js/i18n/de');
        $.fn.select2.defaults.set('theme', 'default');
        $.fn.select2.defaults.set('language', 'de');
        $elements.each((index, element) => {
          const $element = $(element);
          const $parent = $element.parent();
          $parent.css('position', 'relative');

          let placeHolder = $element.data('placeholder');
          if (typeof placeHolder === 'undefined') {
            placeHolder = $element.attr('placeholder');
          }

          $element.select2({
            width: '100%',
            minimumResultsForSearch: -1,
            dropdownParent: $parent,
            placeholder: placeHolder,
          });
          $parent.find('.select2-search__field').css('width', '100%');
        });
      }
    },
  };
})();

export default Select;

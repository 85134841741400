import $ from 'jquery';
import API from './../API';
import Error from './../Error';
import ReportMap from './Map';
import ReportModal from './Modal';
import SliderSlick from './../../../../_modules/slider-slick/SliderSlick';
import AjaxForm from './../AjaxForm';
import Utility from './../Utility';

const ReportDetail = (() => {
  let options = {
    selector: 'a[data-modal-report-detail]',
  };
  let googleMap = null;

  const callApi = function (id, callbackFn) {
    API.getReport((response) => {
      if (!response.success) {
        console.log(`Couldn't load details for report:  ${id}`);
        console.log(response);
        return;
      }
      callbackFn(response.data);
    }, id);
  };

  const fetchData = function ($target, callbackFn) {
    return callApi($target.data('report-id'), callbackFn);
  };

  const onModalShow = (success, $modal, response) => {
    if (success !== true) {
      Error.showUnknown();
      return;
    }
    if (googleMap !== null) {
      googleMap.panTo(new google.maps.LatLng(response.latitude, response.longitude));
      if (googleMap.getZoom() < 17) {
        googleMap.setZoom(17);
      }
    }

    SliderSlick.initialize('#report-detail-modal .slick-slider', {
      arrows: false,
      accessibility: true,
      infinite: true,
      dots: true,
      adaptiveHeight: false,
    });

    const $supportCount = $modal.find('.support-count');
    const reportId = parseInt($modal.data('report-id'), 10);
    $modal.find('.buttons > .btn').click(function (e) {
      e.preventDefault();
      const $element = $(this);
      const isSupport = $element.hasClass('support');
      const shouldActivate = !$element.hasClass('active');
      $element.addClass('disabled');
      API.setReportMeta((resp) => {
        $element.removeClass('disabled');
        if (resp.success) {
          if (isSupport && $supportCount.length > 0) {
            let count = parseInt($supportCount.eq(0).text(), 10);
            if (!Number.isNaN(count)) {
              if (shouldActivate) {
                count++;
              } else {
                count--;
              }
              $supportCount.text(count);
            }
          }
          if (shouldActivate) {
            $element.addClass('active');
          } else {
            $element.removeClass('active');
          }
        } else if (typeof resp.data !== 'undefined') {
          Error.showMessage(resp.data.message);
        } else {
          Error.showUnknown();
        }
      }, reportId, isSupport ? shouldActivate : null, isSupport ? null : shouldActivate);
    });

    const $form = $modal.find('form');
    AjaxForm.setup($form, true);
  };

  const showModal = function (initialize, fetchFn) {
    if (initialize) {
      ReportModal.initialize(options.selector, 'report/detail.hbs', fetchFn, onModalShow);
    } else {
      ReportModal.show('report/detail.hbs', fetchFn, onModalShow);
    }
  };

  return {
    initialize(settings) {
      options = Object.assign(options, settings);

      ReportMap.getMap((map) => {
        googleMap = map;
      });

      showModal(true, fetchData);

      if ($(options.selector).length) {
        const id = parseInt(Utility.getUrlParameter('id'), 10);
        if (id > 0) {
          ReportDetail.showById(id);
        }
      }
    },
    showByData(data) {
      showModal(false, (callbackFn) => {
        callbackFn(data);
      });
    },
    showById(id) {
      showModal(false, (callbackFn) => {
        callApi(id, callbackFn);
      });
    },
  };
})();

export default ReportDetail;

import Modal from './Modal';

const Error = (() => ({
  showUnknown() {
    Modal.showHandlebar('modal/error.hbs', {
      title: 'Fehler!',
      message: 'Ein unbekannter Fehler ist aufgetreten, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    });
  },
  show(title, message) {
    Modal.showHandlebar('modal/error.hbs', {
      title,
      message,
    });
  },
  showMessage(message) {
    Modal.showHandlebar('modal/error.hbs', {
      title: 'Fehler!',
      message,
    });
  },
}))();

export default Error;

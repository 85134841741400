import $ from 'jquery';
import Filter from './Filter';
import Search from './Search';
import LoadingButton from './LoadingButton';
import HandlebarsLoader from './HandlebarsLoader';
import Error from './Error';
import Variables from './Variables';

const LoadMore = (() => ({
  initialize(settings) {
    const options = settings;
    const $element = $$(options.selector);
    const $container = $$(options.selectorContainer);
    if ($element.length > 0 && $container.length > 0) {
      let limit = $element.data('limit');
      if (typeof limit === 'undefined' || limit === null) {
        limit = 5;
      }
      let offset = $container.children().length;
      $element.click((e) => {
        e.preventDefault();
        LoadingButton.start($element);
        HandlebarsLoader.get(options.template, (template) => {
          if (template === null) {
            LoadingButton.stop($element);
            Error.showUnknown();
            $element.hide();
            return;
          }
          const state = Filter.getValue();
          const search = Search.getValue();
          options.fn((response) => {
            LoadingButton.stop($element);
            if (!response.success) {
              Error.showMessage('Es konnten keine weiteren Einträge geladen werden.');
              $element.hide();
              return;
            }
            offset += limit;
            const scroll = $(document).scrollTop();
            for (let i = 0, l = response.data.length; i < l; i++) {
              const data = response.data[i];
              if (data === null || typeof data !== 'object') {
                return;
              }
              const html = template(Object.assign(data, Variables.config));
              $container.append($($.parseHTML(html)).eq(0));
            }
            if (response.data.length < limit) {
              $element.hide();
            }
            $(document).scrollTop(scroll);
          }, offset, limit, search, state, $element);
        });
      });
    }
  },
}))();

export default LoadMore;

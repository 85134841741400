import $ from 'jquery';
import axios from 'axios';
import API from './API';
import LoadingButton from './LoadingButton';

const AjaxForm = (() => {
  let options = {
    selector: 'form[data-ajax-form]',
  };

  const setErrorText = function ($error, message) {
    const fbText = 'Ein unbekannter Fehler aufgetreten, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
    const $list = $('<ul/>');

    if (Array.isArray(message)) {
      for (let i = 0, l = message.length; i < l; i++) {
        $('<li/>')
          .html(message[i])
          .appendTo($list);
      }
    } else if (typeof message === 'string') {
      $('<li/>')
        .html(message)
        .appendTo($list);
    } else {
      $('<li/>')
        .text(fbText)
        .appendTo($list);
    }

    $error.empty().removeClass('hidden').append($list);
  };

  return {
    initialize(settings) {
      options = Object.assign(options, settings);

      const $forms = $(options.selector);
      $forms.each((index, element) => {
        AjaxForm.setup($(element), true);
      });
    },
    setup($form, hideForm, fnSuccess, fnError, fnFinish) {
      const $btnLoader = $form.find('.btn.loader');
      const $fieldsets = $form.find('fieldset');
      const $error = $form.parent().find('.form-error:first');
      const $success = $form.parent().find('.form-success:first');
      const redirect = $form.data('ajax-form-redirect');
      const hide = $form.data('ajax-form-hide');
      const captchaKey = $form.data('captcha-key');
      let captchaAction = $form.data('captcha-action');

      if (typeof captchaAction === 'undefined') {
        captchaAction = 'login';
      }

      if (typeof hide !== 'undefined') {
        hideForm = hide === true || hide === 'true' || hide === true || hide === 'true';
      }

      let enctype = 'application/x-www-form-urlencoded';
      const formEnctype = $form.attr('enctype');
      if (typeof formEnctype === 'string') {
        enctype = formEnctype;
      }

      const action = $form.attr('action');
      let method = 'GET';
      const formMethod = $form.attr('method');
      if (typeof formMethod === 'string') {
        method = formMethod;
      }
      method = method.toLowerCase();

      let success = false;
      $form.submit(function (e) {

        const formData = new FormData(this);
        LoadingButton.start($btnLoader);
        $fieldsets.attr('disabled', true);
        $success.addClass('hidden');
        $error.empty();

        const finalize = function () {
          if (typeof redirect !== 'string' || success !== true) {
            LoadingButton.stop($btnLoader);
            $fieldsets.attr('disabled', false);
            if (typeof fnFinish === 'function') {
              fnFinish();
            }
          }
        };
        const sendForm = function (token) {
          if (typeof token !== 'undefined' && token !== null) {
            formData.append('token', token);
          }
          axios({
            method,
            url: action,
            data: formData,
            headers: {
              'Content-Type': enctype,
              'Cache-Control': 'no-cache',
            },
          }).then((response) => {
            if (API.isValidResponse(response)) {
              if (response.data.status === 'success') {
                success = true;
                if (typeof redirect === 'string') {
                  window.location.replace(redirect);
                } else {
                  $error.empty();
                  if (hideForm) {
                    $form.slideUp(() => {
                      $form.remove();
                    });
                  }
                  $success.removeClass('hidden');
                  if (typeof fnSuccess === 'function') {
                    fnSuccess(response);
                  }
                }
              } else {
                setErrorText($error, response.data.message);
                if (typeof fnError === 'function') {
                  fnError(response.data.message);
                }
              }
            } else {
              setErrorText($error, null);
              if (typeof fnError === 'function') {
                fnError(null);
              }
            }
          }).catch((error) => {
            if (API.isValidErrorResponse(error) && error.response.data !== null) {
              setErrorText($error, error.response.data.message);
              if (typeof fnError === 'function') {
                fnError(error.response.data.message);
              }
            } else {
              setErrorText($error, null);
              if (typeof fnError === 'function') {
                fnError(null);
              }
            }
          }).then(() => {
            finalize();
          });
        };

        try {
          if (typeof captchaKey === 'string' && captchaKey.length > 0) {
            window.grecaptcha.execute(captchaKey, { action: captchaAction })
              .then((token) => {
                sendForm(token);
              });
          } else {
            sendForm();
          }
        } catch (e) {
          setErrorText($error, null);
          finalize();
        }

        e.preventDefault();
      });
    },
  };
})();

export default AjaxForm;

import $ from 'jquery';
import AjaxForm from '../AjaxForm';

const MindMakerDetail = (() => {
  let options = {
    selectorForm: '',
    selectorResult: '',
  };

  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      const $form = $(options.selectorForm);
      const $results = $(options.selectorResult);
      if ($form.length > 0 && $results.length > 0) {
        AjaxForm.setup($form, true, () => {
          $results.slideDown();
        });
      }
    },
  };
})();

export default MindMakerDetail;

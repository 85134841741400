import $ from 'jquery';
import 'slick-carousel';

const SliderSlick = (() => {
  const onSliderInit = function (event, slick) {
    slick.$slider.attr('role', 'slider');
    slick.$slider.attr('aria-valuenow', slick.currentSlide + 1);
    slick.$slider.attr('aria-valuemin', 1);
    slick.$slider.attr('aria-valuemax', slick.slideCount);
  };

  const onSliderSlideChangeEnd = function (event, slick) {
    slick.$slider.attr('aria-valuenow', slick.currentSlide + 1);
  };

  return {
    initialize(settings, sliderSettings) {
      let options = {
        selector: '.slick-slider',
      };

      let sliderOptions = {
        arrows: true,
        accessibility: true,
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

      options = Object.assign(options, settings);
      const $elements = $(options.selector).not('.slick-initialized');
      if ($elements.length) {
        sliderOptions = Object.assign(sliderOptions, sliderSettings);
        $elements.each((index, element) => {
          const $el = $(element);
          if (!$el.hasClass('initialized')) {
            $el.on('init', onSliderInit);
            $el.on('reInit', onSliderInit);
            $el.on('afterChange', onSliderSlideChangeEnd);
            $el.on('destroy', () => {
              $el.off('init reInit afterChange destroy');
            });
            $(options.selector).slick(sliderOptions);
            $el.addClass('initialized');
          }
        });
      }
    },
  };
})();

export default SliderSlick;

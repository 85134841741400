import jQuery from 'jquery';

(function ($, window) {
  const pluginName = '$$';
  const cache = {};

  window[pluginName] = $.fn[pluginName] = function (selector) {
    if (typeof selector !== 'string') {
      return $(selector);
    }
    const tmp = cache[selector];
    if (typeof tmp !== 'undefined') {
      return tmp;
    }
    const $element = $(selector);
    cache[selector] = $element;
    return $element;
  };
  $.fn[pluginName].print = function () {
    console.log(cache);
  };
  $.fn[pluginName].cache = function (selector) {
    return $.fn[pluginName](selector);
  };
  $.fn[pluginName].clear = function (selector) {
    delete cache[selector];
  };
  $.fn[pluginName].reset = function () {
    const keys = Object.keys(cache);
    let index = keys.length;
    while (index--) {
      delete cache[keys[index]];
    }
  };
  $.fn[pluginName].refresh = function (selector) {
    $.fn[pluginName].clear(selector);
    return $.fn[pluginName](selector);
  };
}(jQuery, window));

import $ from 'jquery';
import HandlebarsLoader from './HandlebarsLoader';
import Variables from './Variables';

const Modal = (() => ({
  show($element) {
    $$(document).on($.modal.BEFORE_BLOCK, () => {
      $$('body').addClass('scrollbar-offset');
    });

    $$(document).on($.modal.AFTER_CLOSE, () => {
      $$('body').removeClass('scrollbar-offset');
    });

    $element.modal({
      showClose: false,
      closeExisting: false,
    });
  },
  showDynamic($element) {
    $element.on($.modal.AFTER_CLOSE, (event, modal) => {
      if (modal.$elm.is($element)) {
        $element.remove();
      }
    });

    $element.on($.modal.BEFORE_BLOCK, () => {
      $$('body').addClass('scrollbar-offset');
    });

    $element.on($.modal.AFTER_CLOSE, () => {
      $$('body').removeClass('scrollbar-offset');
    });

    $element.modal({
      showClose: false,
      closeExisting: false,
    });
  },
  showHandlebar(file, data, callbackFn) {
    HandlebarsLoader.get(file, (template) => {
      const html = template(Object.assign(data, Variables.config));
      const $modal = $($.parseHTML(html)).eq(0);

      $modal.on($.modal.AFTER_CLOSE, (event, modal) => {
        if (modal.$elm.is($modal)) {
          $modal.remove();
        }
      });

      $modal.on($.modal.BEFORE_BLOCK, () => {
        $$('body').addClass('scrollbar-offset');
      });

      $modal.on($.modal.AFTER_CLOSE, () => {
        $$('body').removeClass('scrollbar-offset');
      });

      $modal.modal({
        showClose: false,
        closeExisting: false,
      });

      if (typeof callbackFn === 'function') {
        callbackFn($modal);
      }
    });
  },
}))();

export default Modal;

import 'default-passive-events';
import $ from 'jquery';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/progressive/ls.progressive';
import Clipboard from 'clipboard/dist/clipboard.min';
import 'lazysizes';
import 'jquery-modal';
import './Utility/jQueryCache';
// import './Utility/ServiceWorker';
import './Utility/ConsoleInfo';
import './App/Helpers/Handlebars';
import Variables from './App/Variables';
import Utility from './App/Utility';
import CookieConsent from './App/CookieConsent';
import API from './App/API';
import HandlebarsLoader from './App/HandlebarsLoader';
import ImageModal from './App/ImageModal';
import ScrollTo from './App/ScrollTo';
import AjaxForm from './App/AjaxForm';
import Overlay from './App/Overlay';
import Select from './App/Select';
import Menu from './App/Menu';
import FixedInContainer from './App/FixedInContainer';
import Prefetch from './App/Prefetch';
import ReportMap from './App/Report/Map';
import ReportDetail from './App/Report/Detail';
import ReportForm from './App/Report/Form';
import IdeaScoutForm from './App/IdeaScout/Form';
import MindMakerDetail from './App/MindMaker/Detail';
import Filter from './App/Filter';
import Search from './App/Search';
import LoadMore from './App/LoadMore';
import GoogleMap from '../../_modules/google-map/GoogleMap';
import SliderSlick from '../../_modules/slider-slick/SliderSlick';
import FileUpload from './App/FileUpload';

window.jQuery = window.$ = $;
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.loadMode = 1;
window.lazySizesConfig.expand = 300;

const App = (() => ({
  Variables,
  Utility,
  Prefetch,
  Select,
  Menu,
  GoogleMap,
  Slider: SliderSlick,
  initialize: () => {
    Utility.onDocumentReady(() => {
      Variables.initialize();
      Utility.initialize();
      CookieConsent.initialize({
        parent: '#wrapper',
      });
      API.initialize({
        baseUrl: Variables.baseUrl,
      });
      ImageModal.initialize({
        selector: 'a[data-modal-image]',
      });
      ScrollTo.initialize({
        selector: '.scroll-down',
        selectorTarget: '#content',
      });
      ScrollTo.initialize({
        selector: '.scroll-up',
        selectorTarget: '#wrapper',
      });
      Overlay.initialize();
      AjaxForm.initialize();
      HandlebarsLoader.initialize({
        baseUrl: `${Variables.baseUrl}templates/`,
      });
      GoogleMap.initialize({
        selector: '#reports-map .google-map',
      });
      Filter.initialize({
        selector: 'form.content-filters input[type=radio][name=category]',
      });
      Search.initialize({
        selector: 'form.content-search input[type=search][name=search]',
      });
      let markerUrl = `${Variables.baseUrl}assets/template/images/markers/`;
      if (Variables.environment !== 'production') {
        markerUrl = `${Variables.baseUrl}assets/images/markers/`;
      }
      ReportMap.initialize({
        markerUrl,
      });
      ReportDetail.initialize();
      ReportForm.initialize({
        selectorModal: '#report-form-modal',
        selectorGeoLocate: '.geo-locate',
        selectorTrigger: 'a[data-modal-report-form]',
        selectorAutoComplete: '#report-form-modal .google-autocomplete',
      });
      IdeaScoutForm.initialize({
        selectorModal: '#idea-scout-form-modal',
        selectorTrigger: 'a[data-modal-idea-scout-form]',
      });
      MindMakerDetail.initialize({
        selectorForm: '.mind-maker-detail-container .mind-maker-detail form:last',
        selectorResult: '.mind-maker-detail-container .mind-maker-result',
      });
      LoadMore.initialize({
        selector: 'button[data-load-more-reports]',
        selectorContainer: '.content-list',
        template: 'report/entry.hbs',
        fn: API.getReports,
      });
      LoadMore.initialize({
        selector: 'button[data-load-more-ideas]',
        selectorContainer: '.content-list',
        template: 'idea_scout/entry.hbs',
        fn: API.getIdeas,
      });
      LoadMore.initialize({
        selector: 'button[data-load-more-idea-supporter]',
        selectorContainer: '.comments-container:last .comments-alternate',
        template: 'idea_scout/participant.hbs',
        fn: (fn, offset, limit, search, states, $element) => {
          API.getIdeaParticipants(fn, $element.data('idea-scout-id'), offset, limit);
        },
      });
      LoadMore.initialize({
        selector: 'button[data-load-more-minds]',
        selectorContainer: '.content-list',
        template: 'mind_maker/entry.hbs',
        fn: API.getMinds,
      });
      LoadMore.initialize({
        selector: 'button[data-load-more-mind-votes]',
        selectorContainer: '.comments-container:last .comments-alternate',
        template: 'idea_scout/vote.hbs',
        fn: API.getIdeaParticipants,
      });
      Menu.initialize({
        selectorMobileTrigger: '#header .hamburger-menu',
        stickyOffset: $$('#header').outerHeight() + $$('#main-nav').outerHeight(),
      });
      FixedInContainer.initialize({
        selectorContainer: '#reports-map',
        selectorElement: '#reports-map .map .google-map',
        additionalOffset: -Variables.stickyMenuHeight,
      });
      Select.initialize();

      const $profileForm = $('#form-profile');
      const $profileUpload = $profileForm.find('#profile_image');
      if ($profileUpload.length > 0) {
        FileUpload.image($profileUpload, $profileForm.attr('action'), 1);
      }

      new Clipboard('.clipboard-copy');
    });
    Utility.onWindowLoad(() => {
      Prefetch.initialize();
    });
  },
}))();

export default App;

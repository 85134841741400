import $ from 'jquery';
import Variables from './Variables';
import Utility from './Utility';

const Menu = (() => {
  let options = {
    stickyOffset: null,
    selectorNav: '#main-nav',
    selectorUserNav: '#header .user',
    selectorMobileTrigger: '.hamburger-menu',
    selectorStickyOffset: '#header',
    speed: 150,
  };
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      const $window = $$(window);
      const $html = $$('html');
      const $mainNav = $$(options.selectorNav);
      const $userNav = $$(options.selectorUserNav);
      const $mobileTrigger = $$(options.selectorMobileTrigger);
      const $stickyOffset = $$(options.selectorStickyOffset);
      if ($mobileTrigger.length > 0) {
        $mobileTrigger.click(() => {
          if ($html.hasClass('mobile-menu-active')) {
            $mobileTrigger.removeClass('active');
            setTimeout(() => {
              $html.removeClass('mobile-menu-active');
            }, options.speed);
          } else {
            $mobileTrigger.addClass('active');
            $html.addClass('mobile-menu-active');
            $(document).scrollTop(0);
          }
          $mobileTrigger.attr(
            'aria-expanded',
            $mobileTrigger.hasClass('active'),
          );
        });
      }

      if ($userNav.length > 0) {
        $userNav.click(() => {
          $userNav.toggleClass('active');
        });
      }

      if (typeof options.stickyOffset === 'number') {
        const check = function () {
          if ($window.width() <= Variables.breakpoints.lg) {
            $stickyOffset.removeClass('sticky-offset');
            $mainNav.removeClass('sticky');
            return;
          }
          const scrollTop = $window.scrollTop();
          if (scrollTop >= options.stickyOffset) {
            $stickyOffset.addClass('sticky-offset');
            $mainNav.addClass('sticky');
          } else {
            $stickyOffset.removeClass('sticky-offset');
            $mainNav.removeClass('sticky');
          }
        };
        Utility.onWindowLoad(() => {
          check();
        });
        Utility.onDocumentReady(() => {
          check();
        });
        $window.on('resize scroll orientationchange', () => {
          check();
        });
      }
    },
  };
})();

export default Menu;

const Overlay = (() => {
  let options = {
    selector: '#overlay',
  };
  let $overlay = null;
  let $overlayContent = null;
  let $overlayText = null;
  let $overlayLoading = null;
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      $overlay = $$(options.selector);
      $overlayContent = $overlay.find('#overlay-content');
      $overlayText = $overlayContent.find('#overlay-text');
      $overlayLoading = $overlayContent.find('#overlay-loading');
    },
    show(show) {
      if (show) {
        $overlay.removeClass('hidden');
      } else {
        $overlay.addClass('hidden');
      }
      return this;
    },
    setText(text) {
      $overlayText.text(text);
      return this;
    },
    showLoader(show) {
      if (show) {
        $overlayLoading.show();
      } else {
        $overlayLoading.hide();
      }
      return this;
    },
    reset() {
      this.showLoader(false);
      this.setText(null);
      this.show(false);
      return this;
    },
  };
})();

export default Overlay;

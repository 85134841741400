const LoadingButton = (() => ({
  start($element) {
    const loadingText = $element.data('loading-text');
    $element.addClass('loading');
    if (typeof loadingText !== 'undefined' && loadingText !== null) {
      $element.data('loading-default-text', $element.text());
      $element.text(loadingText);
    }
  },
  stop($element) {
    const defaultText = $element.data('loading-default-text');
    $element.removeClass('loading');
    if (typeof defaultText !== 'undefined' && defaultText !== null) {
      $element.text(defaultText);
    }
  },
}))();

export default LoadingButton;

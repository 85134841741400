import $ from 'jquery';

const Search = (() => {
  let options = {
    selector: '',
  };
  let $element = null;
  return {
    initialize(settings) {
      options = Object.assign(options, settings);
      $element = $(options.selector);
    },
    getValue() {
      if ($element !== null && $element.length > 0) {
        return $(options.selector).val();
      }
      return null;
    },
  };
})();

export default Search;
